<template>
	<div ref="body_comment">
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{  program.title }}</h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }} &gt; {{ item_board_config.board_name }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="post_comment_main_view" style="padding: 3rem 0; padding-bottom: 80px" ref="post_comment_main_view">
			<span class="comment_count mt-50" style="padding: 0 2rem">댓글 <em class="count">{{  items_comment.length }}</em>개</span>

			<!-- 댓글 -->
			<template
				v-if="items_comment.length > 0"
			>
			<template
				v-for="(comment, index) in list_comment"
			>
				<template
					v-if="comment.comment_level == 0"
				>
					<div
						v-show="mode == 'comment' || (mode == 'reply' && (comment.comment_number == item_comment.upper_comment_number))"
						:key="'comment_' + index"
						class="comment_layout"
					>
						<div
							v-if="comment.comment_delete_fg == 'Y'"
							class="comment_view" style="padding: 1rem 2rem;"
						>
							<div
								class="item_wrap"
							>
								<div class="write_user">
									<a class="u_profile">
										<img
											:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)"
											@error="$bus.$emit('onErrorImage', $event)"
										/>
									</a>
									<div class="write_desc">
										<strong class="name">{{ $language.common.text_delete_comment }}</strong>
									</div>
								</div>
								<div
									class="comment_body"
								>
									<p></p>
								</div>
							</div>
						</div>
						<div
							v-else
							class="comment_view" style="padding: 1rem 2rem;"
							:class="{ on: mode == 'reply' && comment.comment_number == item_comment.upper_comment_number && item_comment.reply_id == ''}"
						>
							<div
								class="item_wrap"
							>
								<div class="write_user">
									<a
										class="u_profile"
										v-long-press="500"
										@long-press-start="longPress(comment, index)"
									>
										<img
											:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="사진없음"
											@error="$bus.$emit('onErrorImage', $event)"
										>
									</a>
									<div class="write_desc">
										<strong class="name"><em v-if="comment.is_block" class="state-block">차단</em> {{  comment.comment_member_nickname }}</strong>
									</div>
								</div>
								<div
									class="comment_body"
								>
									<p :inner-html.prop="comment.comment | nl2br" style="min-height: 40px; word-break: break-all; "></p>
									<img
										:src="$request.upload_url(comment.comment_img_url)"
										@error="$bus.$emit('onErrorImage', $event)"
									/>
								</div>
								<div
									class="write_info"
								>
									<span class="time">{{  getTimeStory('comment', comment.registdate) }}</span>
									<button
										v-if="mode != 'reply'"
										class="reply"
										@click="setReply(index, comment)"
									>답글쓰기</button>
								</div>
							</div>
						</div>
						<!-- 재댓글 -->

						<!-- //재댓글 -->
					</div>

				</template>
				<template
					v-else
				>
					<div
						:key="'reply_' + index"
						class="replylist_view" style="padding: 0"
					>
						<div
							v-if="mode == 'comment' || (mode == 'reply' && comment.upper_comment_number == item_comment.upper_comment_number)"
							class="item_wrap" style="padding: 1rem 1rem 1rem 2.4rem; margin: 0"
							:class="{ on: comment.comment_number == item_comment.reply_id}"

						>
							<div style="margin-left: 3.2rem">
								<div style="display: flex">
									<a
										class="u_profile" style="margin-right: 10px; flex-basis: 3.2rem;"
										v-long-press="500"
										@long-press-start="longPress(comment)"
									>
										<img
											:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="사진없음"
											@error="$bus.$emit('onErrorImage', $event)"
										>
									</a>
									<div style="flex: 1">

										<div class="write_desc" style="height: 3.2rem; line-height: 3.2rem">
											<p v-if="comment.comment_delete_fg == 'Y'" style="font-size: 1.6em">{{ $language.common.text_delete_comment}}</p>
											<strong
												v-else
												class="name" style="font-size: 1.6em"
											><em v-if="comment.is_block" class="state-block">차단</em> {{ comment.comment_member_nickname }}</strong>

										</div>
										<div
											v-if="comment.comment_delete_fg != 'Y'"
											class="comment_body" style="position: relative"
										>
											<button
												v-if="comment.comment_number != comment.upper_comment_number"
												class="hashtag" style="position: absolute; left: 0"
											>&#64;{{ (comment.upper_comment_member_nickname ? comment.upper_comment_member_nickname : comment.comment_member_nickname) }}</button>

											<p :inner-html.prop="'@' + (comment.upper_comment_member_nickname ? comment.upper_comment_member_nickname : comment.comment_member_nickname) + ' ' + comment.comment | nl2br" style="min-height: 40px"><template v-if="comment.comment_number != comment.upper_comment_number"></template> </p>

											<img
												:src="$request.upload_url(comment.comment_img_url)"
												@error="$bus.$emit('onErrorImage', $event)"
											/>

											<div class="write_info">
												<span class="time">{{  getTimeStory('reply', comment.registdate) }}</span>
												<button
													v-if="mode != 'reply'"
													class="reply"
													@click="setReply(index, comment)"
												>답글쓰기</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>

			<!-- 댓글 -->
			</template>
			</template>

			<div
				v-else
				class="list_none"
			>
				<img :src="require('@/assets/image/list_none.png')" alt="이력없음">
				<span>등록된 댓글이 없습니다.</span>
			</div>
		</div>

		<div class="comment_write">
			<div
				v-if="item_comment.img_src"
				class="photo_area"
			>
				<div class="photo_area_view">
					<img
						v-if="item_comment.img_src"
						:src="$request.upload_url(item_comment.img_src)"
					/>
				</div>
				<button
					@click="item_comment.img_src = ''"
					class="view_close">
					<i class="icon-close"></i>
				</button>
			</div>
			<div class="write_area">
				<div class="container">
					<h3 class="hide">댓글을 남겨주세요.</h3>
					<div class="write_main">
						<div class="btn_upload">
							<label class="comment_albumfile" aria-label="사진올리기"><input_file @change="setFile" accept="image/*" /></label>

						</div>
						<div class="mentions" >
							<textarea
								v-model="item_comment.content"
								placeholder="댓글을 남겨주세요."

								@input="reSize"
								:style="item_comment.content == '' ? 'height: 20px': ''"
							></textarea>
						</div>
						<div class="submit_wrap">
							<button
								@click="postComment()"
								type="submit" class="write_submit"
							></button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<mafia062
			v-if="is_062"

			:user="user"
			:cartel="cartel"
			:bbs="bbs"
			:item_comment="item_target"

			@cancel="is_062 = false"
			@click="getBbsComment"
			@modify="onModify"
			@delete="deleteComment"
			style="z-index: 1003"
		>
		</mafia062>

		<mafia0632
			v-if="is_0632"

			:user="user"
			:cartel="item_cartel"
			:bbs="bbs"
			:comment="item_target"

			@cancel="is_0632 = false"
			@click="update"
			class="layer-popup"
		></mafia0632>

	</div>
</template>

<style>
	.item_wrap.on,
	.comment_view.on { background-color: #eee}
</style>

<script>
	import mafia062 from '@/view/Cartel/mafia062'
	import mafia0632 from '@/view/Cartel/mafia063-2'
	import input_file from '@/components/InputFile'

export default {
	name: 'comment_list'
	, props: ['cartel', 'bbs', 'user']
	, components: {mafia062, input_file, mafia0632}
	, data: function(){
		return {
			program: {
				name: '댓글작성'
				, title: '댓글 작성'
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, item_comment: {
				cartel_id: this.$route.params.idx
				, bbs_code: this.$route.params.code
				, bbs_id: this.$route.params.bbs_id
				, user_id: this.user.id
				, user_nick: this.user.nick
				, user_img_src: this.user.img_src
				, upper_comment_number: ''
				, upper_comment_member_nickname: ''
				, content: ''
				, comment_img_url: ''
				, comment_level: 0
				, comment_member_profle_nft_card_img_url: ''

			}
			, items_comment: []
			, rows: 2
			, mode: 'comment'
			, item_cartel: {}
			, is_062: false
			, item_target: {}
			, scrollTop: 0
			, item_search: {
				page_number: 1
				, pagerecnum: this.$language.base.pagerecnum
			}
			, is_scroll: true
			, item_board_config: {}
			, upload_img: ''
			, h: 0
			, upper_index: 0
			, s_top: 0
			, is_0632: false
		}
	}
	, computed: {
		list_comment: function(){
			return this.items_comment.filter(function(item){
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				return item
			})
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.items_cartel_notice = result.data.notice_mttrs_list
					this.items_cartel_bbs = result.data.post_list

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getBoardConfig: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
					}
					, type: true
				})

				if(result.success){
					this.item_board_config = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getBbs: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
					}
					, type: true
				})

				if(result.success){
					this.item_bbs = result.data

				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBbsComment: async function(){
			try{
				this.is_scroll = false
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs_comment_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(this.items_comment.length > 0){
						this.$log.console('concat')
						this.items_comment = this.items_comment.concat(result.data.comment_list)
					}else{
						this.$log.console('!!concat')
						this.items_comment = result.data.comment_list
					}

					this.item_search.page_number++

					if(result.data.comment_list.length > 0){
						this.getScroll()
					}else{
						this.removeScroll()
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postFile: async function(){
			try{
				// this.$bus.$emit('on', true)
				if(!this.upload_img){
					return true
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_file
					, data: {
						member_number: this.user.member_number
						, file_upload_code: 'CM00700003'
						, appendix_file_div_code: 'CA01000001'
						, appendix_file_name: this.upload_img
					}
					, multipart: true
					, type: true
				})

				if(result.success){
					this.item_comment.comment_img_url = result.data.file_url
					return true
				}else{
					throw result.message
				}

			}catch (e) {
				this.$log.console('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
				this.upload_img = ''
			}
		}

		, postComment: async function(){
			try{
				if(!this.item_comment.content || this.item_comment.content == ''){
					throw '댓글을 입력하세요'
				}
				// this.$bus.$emit('on', true)

				if(await this.postFile()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_comment
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
							, upper_comment_number: this.item_comment.upper_comment_number
							, upper_commnet_number_nickname: this.item_comment.upper_comment_member_nickname
							, comment: this.item_comment.content
							, comment_img_url: this.item_comment.comment_img_url
						}
						, type: true
					})

					if(result.success){
						this.item_comment.comment_number = result.data.comment_number
						this.item_comment.registdate = this.$date.getNow('-')
						this.item_comment.comment = this.item_comment.content
						this.item_comment.comment_member_profle_nft_card_img_url = this.user.profle_nft_card_img_url
						this.item_comment.comment_level = this.item_comment.upper_comment_number ? 1 : 0
						this.item_comment.comment_member_nickname = this.user.nickname
						this.item_comment.comment_member_number = this.user.member_number

						await this.appendComment(this.item_comment)
						this.item_comment.content = ''
						this.item_comment = {
							cartel_id: this.$route.params.idx
							, bbs_code: this.$route.params.code ? this.$route.params.code : this.bbs.board_type_code
							, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.board_number
							, user_id: this.user.id
							, user_nick: this.user.nick
							, user_img_src: this.user.img_src
							, upper_comment_number: ''
							, upper_comment_member_nickname: ''
							, content: ''
							, comment_img_url: ''
						}

						this.mode = 'comment'
						setTimeout(() => {
							this.$refs.body_comment.scrollTop = this.$refs.body_comment.scrollHeight
						}, 200)

						this.$emit('click')
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getTimeStory: function(type, created_at){
			return this.$date.getTimeStory(created_at)
		}
		, setFile: function(e){

			this.upload_img = e[0]
			this.$log.console('this.upload_img', this.upload_img)

			const reader = new FileReader()
			
			reader.onload = (e) => {
				// this.$log.console('onload', e)
				this.$set(this.item_comment, 'img_src', e.target.result)
			}
			reader.readAsDataURL(e[0])

		}

		, reSize: function(e){
			e.target.style.height = 'auto'

			e.target.style.height = e.target.scrollHeight + 'px'

			this.$log.console(e.target.innerHeight)
			if(Number(e.target.style.height.replace('px', '')) >= 120) {
				e.target.style.height = 120 + 'px'
			}else{
				if(e.target.style.height == '40px'){
					if(e.target.value.search(/\n/g) == -1){
						e.target.style.height = '20px'
					}
				}
			}
		}
		, setReply: function(index, comment){

			this.s_top = this.$refs.body_comment.scrollTop
			this.upper_index = index
			if(comment.comment_level == 0){
				this.item_comment.upper_comment_number = comment.comment_number
			}else{
				this.item_comment.upper_comment_number = comment.upper_comment_number
			}

			this.item_comment.upper_comment_member_nickname = comment.comment_member_nickname
			this.$set(this.item_comment, 'reply_id', comment.comment_level == 0 ? '' : comment.comment_number)
			this.mode = 'reply'
			this.program.title = '답글 작성'

			this.$log.console(`comment.upper_comment_number :${comment.upper_comment_number}, comment.comment_number: ${comment.comment_number}, this.item_comment.reply_id: ${this.item_comment.reply_id}`)

		}
		, goBack: function(){
			if(this.mode == 'comment'){
				this.$emit('goBack')
			}else{
				this.mode = 'comment'
			}

			this.item_comment = {}
			this.program.title = '댓글 작성'

		}
		, longPress: function(item, index){
			this.$log.console('longPress', item)
			this.is_062 = true
			this.item_target = item
			this.item_target.index = index
		}
		, scrollListen: function(){
			if(this.mode == 'reply'){
				return false
			}else{

				let w = window.innerHeight
				let b = this.$refs.post_comment_main_view.scrollHeight
				let t = this.$refs.body_comment.scrollTop

				let max = b - w
				if(max <= 0){
					max = 0
				}

				if(t > max){
					this.getBbsComment()
				}
			}
		}
		, removeScroll: function(){
			this.$log.console('removeScroll')
			this.$refs.body_comment.removeEventListener('scroll', this.scrollListen)
		}
		, getScroll: function(){
			this.$log.console('getScroll')
			this.h = this.$refs.post_comment_main_view.scrollHeight
			this.$refs.body_comment.addEventListener('scroll', this.scrollListen);
		}
		,appendComment: function(obj){

			if(obj.upper_comment_number){

				this.items_comment.splice(this.upper_index + 1, 0, obj)

				setTimeout(() => {
					this.$refs.body_comment.scrollTop = this.s_top
				}, 100)
			}else{
				this.items_comment.push(obj)
			}
		}
		, onModify: function(){
			this.is_062 = false
			this.is_0632 = true
		}
		, update: function(item){
			this.$log.console('update', this.item_target, item)

			item.comment = item.content
			this.$set(this.items_comment, this.item_target.index, item)

			this.is_0632 = false
		}
		, deleteComment: function(){
			this.items_comment.splice(this.item_target.index, 1)
			this.is_062 = false
		}
	}

	,async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getBoardConfig()
		await this.getBbsComment()
		this.$log.console('reply_id', this.$route.params.reply_id)


	}
}
</script>